.red {
    color: red;
}

.login-form-forgot {
    float: right;
}

.limiter{
    background-image: url("../images/login.jpg") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-attachment: fixed;
}

.background-login-page::before {
    content: "";
    /* background-color: rgba(33,33,33,.6); */
    background-color: rgba(253, 253, 253, 0.5);  
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }